import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../../general/Select';
import {Button} from 'antd';
import {ReportColumn} from '../../../../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class ReportDefinitionColumnSelect extends React.PureComponent {
    static propTypes = {
        selectedColumnName: PropTypes.string,
        columns: PropTypes.arrayOf(ReportColumn.isRequired).isRequired,
        onChange: PropTypes.func.isRequired,
        hasMargin: PropTypes.bool,
        onDelete: PropTypes.func,
        before: PropTypes.node,
        after: PropTypes.node,
    };

    render() {
        const {selectedColumnName, columns, onChange, hasMargin, onDelete, before, after} = this.props;
        return <div
            className="d-flex align-items-center flex-wrap"
            style={hasMargin == true ? {marginLeft: '4rem'} : {}}
        >
            {before}
            <Select
                className="report-value-input d-inline-block my-1"
                onChange={onChange}
                options={columns.map(column => {
                    return {
                        label: column.label,
                        value: column.name,
                    }
                })}
                value={selectedColumnName}
                required={true}
            />
            {after}
            {onDelete != null ? <Button onClick={onDelete} type="danger" icon="delete"/> : null}
        </div>;
    }

}

export default ReportDefinitionColumnSelect;